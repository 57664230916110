.associated {
    background-color: gray;
    height: 90%;
    margin-left: 2%;
    margin-bottom: 2%;
}

.no-results-found {
    margin-top: 20%;
    margin-bottom: 5%;
}