.highlighted {
  background-color: #f0d6c3;
}

.modal-section {
  border-bottom: 2px solid var(--beige);
}
.modal-section:last-of-type {
  border-bottom: 0;
}

.quote-modal-text {
  color: black;
  width: 80%;
  margin: 0 auto;
  padding-top: 4%;
  padding-bottom: 4%;
  line-height: 1.5;
  display: block;
  font-size: 1rem;
}

.quote-modal-thread-link {
  color: black;
  line-height: 1.5;
  font-size: 1rem;
}

.link-container {
  width: 80%;
  margin: 0 auto;
  text-align: end;
  padding-bottom: 3%;
}

.quote-modal-wrapper {
  width: 20%;
  height: 80%;
}

.quote-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Futurice, Roboto;
  height: 90%;
  width: 80%;
  margin: 0 auto;
  background-color: white;
  border: solid 3px var(--beige);
}
.quote-modal-heading {
  font-size: 0.9rem;
  top: 95px;
  width: 100%;
  background: white;
  border-bottom: 2px solid var(--beige);
  z-index: 1001;
}
.quote-modal-heading-text {
  text-align: center;
  padding-top: 1%;
  color: black;
  flex: 1;
}
.close-button {
  top: 0;
  right: 0;
  position: absolute;
  float: right;
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 2rem;
}
.quote-modal-content {
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
}
.quote-modal-footer {
  bottom: 95px;
  width: 100%;
  background: #fff;
  border-top: 2px solid var(--beige);
  z-index: 1002;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,0.7) !important;
}



.quote-modal-page-links {
  color: black;
  margin: 0 auto;
  width: 80%;
}

.quote-modal-page-links span {
  margin: 2px;
  cursor: pointer;
}

.active {
  font-weight: bold;
}

.pagination {
  padding-top: 2.5%;
  padding-bottom: 2%;
  text-align: center;
  margin: 0 auto;
  -webkit-padding-start: 0;
}


.pagination p {
  color: black;
  padding: 0;
  margin: 0;
}

.pagination-prev-next {
  font-family: Futurice;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #4a4a4a;
  padding-left: 20px;
  padding-right: 20px;
}

.disabled a {
  pointer-events: none;
  cursor: default;
  color: gray !important;
}

.pagination-page-link {
  font-family: Futurice;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.7px;
  text-align: center;
  color: #4a4a4a;
}

.pagination a {
  color: black;
  padding: 5px;
  cursor: pointer;
}

.pagination-active a {
  color: var(--purpley);
  text-decoration: underline;
}

.pagination ul {
  display: inline-block;
}

.pagination li {
  display: inline-block;  
}

.pagination .break a {
  cursor: default;
}

.pagination-container {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.quote-modal-footer-hidden {
  visibility: hidden;
}