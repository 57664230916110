div.tooltip {
    position: absolute;
    height: 28px;
    padding: 5px 10px;
    font-family: "Futurice";
    font-size: 12px;
    background: white;
	  border: solid 2px var(--beige);
    pointer-events: none;
}

.associated-chart {
    margin-bottom: 5%;
}

.no-results-found-container {
    margin-bottom: 5%;
}