
@media screen and (min-width: 480px) {
  .association-result {
    display: flex;
    flex-wrap: wrap;
  }
  .left-padding {
    padding-left: 75px;
  }
}

@media screen and (max-width: 480px) {
  .search-term-info {
    margin-top: 80px !important;
  }
  .left-padding {
    padding-left: 0;
  }
}

.search-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 1%;
    padding-bottom: 1%;
    background: #212121;
}

.search-page {
  max-width: 960px;
  width: 95%;
  margin: 0 auto;
}

.search-term-info {
  padding-top: 3%;
  max-width: 960px;
  padding-left: 3%;
  padding-right: 3%;
  margin: 0 auto;
}

.form {
  width: 100%;
}

.association-result {
  max-width: 960px;
  min-height: 480px;
  padding-left: 3%;
  padding-right: 3%;
  border-bottom: 2px solid #717171;
  padding-bottom: 5%;
}

.association-result-left {
  flex: 2;
  font-size: 2rem;
  min-width: 320px;
}

.chart {
  flex: 4;
  min-width: 240px;
}

.minor-margin {
  margin: 2%;
}

.move-slider {
  margin-top: 4%;
  text-align: left;
}

.sk-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 128px;
  width: 128px;
}

.footer {
  margin: 2%;
}

.footer p {
  color: white;
  margin: 0;
  line-height: 1.79;
}

.footer a {
  color: white;
}

.chart-container {
  min-height: 300px;
}

.chart-side-bar .body-text {
  font-size: 0.85rem;
}
.chart-side-bar {
  margin-right: 10%;
}

.post-link-container {
  padding-top: 5px;
  width: 200px;
}

.post-link-icon {
  width: 30px;
  float: left;
}

.post-link-text {
  width: 170px;
  float: left;
}

.basket-link-container {
  padding-top: 15px;
}