
/* On mobile devices we want a full-sided basket */
@media only screen and (max-width: 480px)  {
  .basket-modal {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-width: 481px)  {
  .basket-modal {
    width: 40%;
    float: left;
    margin: 3%;
  }
}

.basket-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  background-color: white;
  border: solid 4px var(--beige);
  font-family: 'Futurice', Roboto;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.basket-modal-heading {
  font-size: 0.9rem;
  top: 95px;
  width: 100%;
  background: white;
  border-bottom: 2px solid var(--beige);
  z-index: 1001;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.basket-modal-heading-text {
  text-align: center;
  padding-top: 1%;
  color: black;
  flex: 1;
}
.basket-modal-content {
  overflow: overlay;
  max-height: 800px;
  z-index: 1000;
  color: black;
  font-size: 1rem;
  margin: 2%;
}
.basket-modal-content p {
  margin: 0;
  color: black;
}