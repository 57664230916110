@media only screen and (min-width: 1001px)  {
  .chart-side-bar {
    margin-top: 31%; 
  }
}

.slider-container {
  position: relative;
  height: 90px;
  color: #d8d8d8;
  margin-top: 13px;
}

.post-count-sliderx {
  position: absolute;
  margin-bottom: 20%;
}

.slider-text-top-left {
  position: absolute;
  top: 22%;
  left: 0;
}

.slider-text-top-right {
  position: absolute;
  top: 22%;
  right: 0;
}