.disclaimer-container {
  position: fixed;
  top: 20%;
  width: 75%;
  height: 60%;
  min-width: 360px;
  left: 50%;
  margin-left: -37%;
  background-color: var(--purpley);
  max-height: 450px;
}

.disclaimer-container h3 {
  text-align: center;
  color: white;
  font-family: 'Futurice';
  vertical-align: middle;
  font-weight: normal;
  font-size: 2rem;
  margin: 0;
  margin-top: 6%;
}

.disclaimer-container button {
  margin: 2%;
  height: 50px;
  width: 150px;
  background-color: var(--purpley);
  border: 2px solid white;
  font-size: 1rem;
  cursor: pointer;
  float: right;
  color: white;
  margin: 0;
  margin-right: 5%;
  margin-top: 5%;
}
.disclaimer-container button:hover {
  background-color: white;
  color: var(--purpley);
}  
.disclaimer-container p {
  color: white;
  font-size: 0.9rem;
  margin: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
  font-weight: normal;
}