/* Landing Page */

@media only screen and (max-width: 480px)  {
  html {
    font-size: 16px !important;
  }
  .body-text-container {
    flex-wrap: wrap;
  }
  
  .chilicorn.hero {
    height: 200px;
  }
  
  .heading-1-chilicorn {
	  padding-top: 10%;
  }

  .most-common-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .numbers-duo {
    flex-wrap: wrap-reverse;
  }

  .quotes-icon {
    margin: 0;
    display: flex;
    align-items: normal;
    width: 85%;
  }

  .numbers-duo .numbers-duo-icon {
    margin: 0 auto;
  }
  

  .numbers-duo-calendar-container {
    margin: 0 auto;
  }

  .numbers-duo img {
    height: 67%;
  }

  .numbers-duo-calendar-text {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 480px)  {
  html {
    font-size: 19px !important;
  }
  .chilicorn.hero {
    height: 375px;
  }

  .heading-1-chilicorn {
    padding-top: 5%;
  }

  .quotes-icon {
    margin-left: -44%;
    margin-bottom: -44%;    
  }
  .numbers-duo-calendar-text {
    font-size: 0.75rem;
  }

  .most-common-container {
    display: flex; 
  }
}

body {
  background: #212121;
  font-weight: 400;
  margin: 0;
}

.content {
  max-width: 960px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.is-centered {
  text-align: center;
}

.body-text-container {
  flex-direction: column;
  display: flex;
  margin: 2%;
  margin-top: 4%;
}

.most-common-container {
  margin: 2%;
  margin-top: 5%;
  max-width: 960px;
}

.most-common-container div {
  flex: 1;
}

a {
  color: white;
}

.form-box {
  max-width: 83.5%;
  display: flex;
  margin: 0 auto;
}

.more-information {
  min-height: 350px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #e8e8e8;
}

.more-information * {
  color: black;
}

.more-information .feedback-button {
  width: 200px;
  height: 45px;
}

.more-information a:not(.feedback-button) {
  color: black;
}

.more-information p {
  margin: 0 auto;
  color: black;
}

.more-information h3 {
  font-weight: normal;
  margin: 0;
  line-height: 1;
}

.intro-paragraph {
  line-height: 1.3rem;
  margin-top: 5%;
  margin-right: 7%;
  margin-left: 7%;
  margin-bottom: 2%;
  width: 85%;
}

.chilicorn-fund {
	margin: 0;
	padding: 0;
  background-size: cover;
}

.chilicorn-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  max-width: 90%;
  position: relative;
  text-align: center
}

.chilicorn-container h1 {
  text-align: center;
  font-size: 61px;
  font-weight: 800;
  padding-bottom: 10px;
  font-family: "Space mono"
}

.chilicorn-container h2 {
  font-size: 53px;
  font-weight: 800;
  padding-bottom: 30px
}

.chilicorn-container:after {
  content: " ";
  display: block;
  clear: both
}

.hero {
  color: #000;
  text-align: center;
  padding: 40px 0 0px 0;
  margin-bottom: 0px
}

.hero p,.hero h2 {
  font-size: 23px;
  font-weight: 300;
  max-width: 20em;
  margin: auto;
  opacity: 0.7;
  font-family: "space mono"
}

.hero a,.hero a:visited {
  color: #000
}

.hero h1 {
  color: #000
}

.hero img.chilicorn {
  width: 40%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px
}

.chilicorn.hero {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #615a47;
  background-image: url("./chilicorn-world-no-clouds-no-corn.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.chilicorn.hero h1,.chilicorn.hero h2,.chilicorn.hero p,.chilicorn.hero a,.chilicorn.hero a:hover,.chilicorn.hero a:visited,.chilicorn.hero a:active {
  color: white;
  text-shadow: 1px 1px 1px gray
}

.chilicorn.hero h1 {
  margin: 0
}

.chilicorn.hero h2 {
  font-weight: 100;
  opacity: 1;
  line-height: 1.1;
}

.chilicorn.hero .container {
  z-index: 100
}

.chilicorn-static {
  position: absolute;
  left: 33%;
  bottom: 5%;
  width: 110px !important;
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.logo {
  height: 169px;
  width: 169px;
  display: block;
  margin: auto;
  margin-top: 2%;
}

/* Text styles */

.title {
  margin-top:0;
  font-family: 'Space Mono', monospace;
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  -webkit-margin-after: 0;
}

.heading-1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-top: 2px;
}

.term-name {
  font-size: 45px;
  font-weight: bold;
  color: #ffffff;
}

.body-text {
  font-size: 1rem;
  line-height: 1.39;
  color: #d8d8d8;
}

.small-text {
	margin: 2%;
	font-size: 16px;
	line-height: 1.31;
	color: #ffffff;
}

.text-link {
	font-size: 14px;
	text-align: center;
	color: #ffffff;
}

.really-small-text {
  margin-top: 0;
  font-size: 0.70rem;
  line-height: 1.42;
  color: #d8d8d8;
}

.whole-story {
  float: right;
}

::-webkit-input-placeholder {
   font-size: 0.8rem;
}

a:hover:not(.feedback-button) {
  color: var(--purpley);
}

.left-align {
  text-align: left;
}

.numbers-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 150px;
  justify-content: space-around;
}

.numbers-duo {
  display: flex;
}

.icon-centered {
  display: flex;
  align-items: initial !important;
  margin-bottom: 54%;
}

.numbers-duo-text-large {
  font-family: Futurice;
  font-weight: normal;
  font-size: 2rem;
  line-height: 0.7;
  margin: 0;
  text-align: center;
}

.numbers-duo-text-small {
  font-family: Futurice-monospaced;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
  margin: 0;
}

.numbers-duo-text {
  display: flex;
  align-items: flex-end;
}

.quotes-icon {
  display: flex;
}

.numbers-duo-icon {
  display: flex;
  align-items: end;
  
}

.calendar-icon {
  display: flex;
  margin: 0 auto;
}

.numbers-duo-calendar-container {
  height: 67%; 
}

.numbers-duo .numbers-duo-text {
  margin: 0 auto;
}

.numbers-duo-calendar-text {
  text-align: center;
  font-family: 'Futurice-monospaced';
}

.numbers-duo-calendar-text p {
  color: white;
  font-weight: normal;
  font-family: 'Futurice-monospaced', 'Roboto';
  position: relative;
}

.most-common-tooltip {
  height: 14px !important;
}

.icon-text {
  position: relative;
  flex: 1;
  font-family: 'Futurice-monospaced', 'Roboto';
  font-size: 0.85rem;
  margin: -45% auto;
}
.calendar-container {
  display: flex;
  flex-direction: column;
}

.common-title-container {
  margin: 0 auto;
}

.posts-tiny-icon {
  margin-left: 5px;
  vertical-align: middle;
}

.bar-hint {
  line-height: 100%;
}