@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Space+Mono:400,600,700');
@font-face {
  font-family: Futurice;
  src: local('Futurice'), url(../fonts/FuturiceRegular.otf);
}

@font-face {
  font-family: Futurice;
  src: local('Futurice'), url(../fonts/FuturiceBold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Futurice-monospaced';
  src: local('Futurice-monospaced'), url(../fonts/FuturiceMonospaced.otf);
}
/* Color palette */

:root {
	--sunshine-yellow-60: rgba(255, 230, 56, 0.6);
	--sunshine-yellow: #ffe638;
	--white-60: rgba(216, 216, 216, 0.6);
	--purpley: #9f6ce3;
	--white-two: #e6e6e6;
	--soft-green: #6ec872;
	--white: #d8d8d8;
	--hot-magenta: #d903ca;
	--coral: #ff5240;
	--beige: #f0d6c3;
	--bright-blue: #0068fd;
}

.main {
  padding-top: 2%;
  margin: 0 auto;
  font-family: 'Futurice', Roboto;
}

h1, h2, h3, h4, h5, h6 {
  color: white;
  font-weight: bold;
}

p {
  color: var(--white);
  font-family: Futurice, Roboto;
}

.is-tight {
  margin: 0;
}