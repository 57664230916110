.warning {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  padding-right: 10px;
}

.warning-container {
  display: relative;
  margin: 0 auto;
}

.warning-container p {
  text-align: center;
  margin-left: 500px;
}

.warning-text {
  margin-top: 0;
  line-height: 1.42;
}