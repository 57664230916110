.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.top-margin {
  margin-top: 25px;
}

.bottom-margin {
  margin-bottom: 10px;
}

.margin-small-vertical {
  margin: 0;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.margin-small-vertical2 {
  margin: 0;
  margin-bottom: 0.5em;
  margin-top: 0.25em;
}

.monospaced {
  font-family: Futurice-monospaced;
}

.size-45 {
  font-size: 2.42rem;
}

.size-27 {
  font-size: 1.45rem;
}

.size-18 {
  font-size: 0.97rem;
}

.size-16 {
  font-size: 0.86rem;
}

.size-14 {
  font-size: 0.75rem;
}

.size-13 {
  font-size: 0.70rem;
}

.heading-1-white {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: black;
  padding-top: 0%;
}

.heading-2 {
  font-weight: 400;
  margin: 1rem;
  margin-top: 50px;
  font-size: 2rem;
  color: #ffffff;
}

.heading-3 {
  font-weight: bold;
  margin: 1rem;
  margin-top: 50px;
  margin-bottom: 10%;
  font-size: 2rem;
  color: #ffffff;
}

.heading-4 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  margin: 2%;
  -webkit-margin-after: 0;
}

.search-heading {
  font-size: 1.2rem;
  font-family: 'SpaceMono', monospace;
  font-weight: bold;
}

.whiteish {
  color: #d8d8d8;
}

.white {
  color: #fff;
}

.yellow {
  color: #ffe638;
}

.darkyellow {
  color: #f4be97;
}