@import url(https://fonts.googleapis.com/css?family=Roboto:400,700|Space+Mono:400,600,700);
/* Landing Page */

@media only screen and (max-width: 480px)  {
  html {
    font-size: 16px !important;
  }
  .body-text-container {
    flex-wrap: wrap;
  }
  
  .chilicorn.hero {
    height: 200px;
  }
  
  .heading-1-chilicorn {
	  padding-top: 10%;
  }

  .most-common-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .numbers-duo {
    flex-wrap: wrap-reverse;
  }

  .quotes-icon {
    margin: 0;
    display: flex;
    align-items: normal;
    width: 85%;
  }

  .numbers-duo .numbers-duo-icon {
    margin: 0 auto;
  }
  

  .numbers-duo-calendar-container {
    margin: 0 auto;
  }

  .numbers-duo img {
    height: 67%;
  }

  .numbers-duo-calendar-text {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 480px)  {
  html {
    font-size: 19px !important;
  }
  .chilicorn.hero {
    height: 375px;
  }

  .heading-1-chilicorn {
    padding-top: 5%;
  }

  .quotes-icon {
    margin-left: -44%;
    margin-bottom: -44%;    
  }
  .numbers-duo-calendar-text {
    font-size: 0.75rem;
  }

  .most-common-container {
    display: flex; 
  }
}

body {
  background: #212121;
  font-weight: 400;
  margin: 0;
}

.content {
  max-width: 960px;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.is-centered {
  text-align: center;
}

.body-text-container {
  flex-direction: column;
  display: flex;
  margin: 2%;
  margin-top: 4%;
}

.most-common-container {
  margin: 2%;
  margin-top: 5%;
  max-width: 960px;
}

.most-common-container div {
  flex: 1 1;
}

a {
  color: white;
}

.form-box {
  max-width: 83.5%;
  display: flex;
  margin: 0 auto;
}

.more-information {
  min-height: 350px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #e8e8e8;
}

.more-information * {
  color: black;
}

.more-information .feedback-button {
  width: 200px;
  height: 45px;
}

.more-information a:not(.feedback-button) {
  color: black;
}

.more-information p {
  margin: 0 auto;
  color: black;
}

.more-information h3 {
  font-weight: normal;
  margin: 0;
  line-height: 1;
}

.intro-paragraph {
  line-height: 1.3rem;
  margin-top: 5%;
  margin-right: 7%;
  margin-left: 7%;
  margin-bottom: 2%;
  width: 85%;
}

.chilicorn-fund {
	margin: 0;
	padding: 0;
  background-size: cover;
}

.chilicorn-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  max-width: 90%;
  position: relative;
  text-align: center
}

.chilicorn-container h1 {
  text-align: center;
  font-size: 61px;
  font-weight: 800;
  padding-bottom: 10px;
  font-family: "Space mono"
}

.chilicorn-container h2 {
  font-size: 53px;
  font-weight: 800;
  padding-bottom: 30px
}

.chilicorn-container:after {
  content: " ";
  display: block;
  clear: both
}

.hero {
  color: #000;
  text-align: center;
  padding: 40px 0 0px 0;
  margin-bottom: 0px
}

.hero p,.hero h2 {
  font-size: 23px;
  font-weight: 300;
  max-width: 20em;
  margin: auto;
  opacity: 0.7;
  font-family: "space mono"
}

.hero a,.hero a:visited {
  color: #000
}

.hero h1 {
  color: #000
}

.hero img.chilicorn {
  width: 40%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px
}

.chilicorn.hero {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #615a47;
  background-image: url(/static/media/chilicorn-world-no-clouds-no-corn.bfa62049.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.chilicorn.hero h1,.chilicorn.hero h2,.chilicorn.hero p,.chilicorn.hero a,.chilicorn.hero a:hover,.chilicorn.hero a:visited,.chilicorn.hero a:active {
  color: white;
  text-shadow: 1px 1px 1px gray
}

.chilicorn.hero h1 {
  margin: 0
}

.chilicorn.hero h2 {
  font-weight: 100;
  opacity: 1;
  line-height: 1.1;
}

.chilicorn.hero .container {
  z-index: 100
}

.chilicorn-static {
  position: absolute;
  left: 33%;
  bottom: 5%;
  width: 110px !important;
}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.logo {
  height: 169px;
  width: 169px;
  display: block;
  margin: auto;
  margin-top: 2%;
}

/* Text styles */

.title {
  margin-top:0;
  font-family: 'Space Mono', monospace;
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  -webkit-margin-after: 0;
}

.heading-1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-top: 2px;
}

.term-name {
  font-size: 45px;
  font-weight: bold;
  color: #ffffff;
}

.body-text {
  font-size: 1rem;
  line-height: 1.39;
  color: #d8d8d8;
}

.small-text {
	margin: 2%;
	font-size: 16px;
	line-height: 1.31;
	color: #ffffff;
}

.text-link {
	font-size: 14px;
	text-align: center;
	color: #ffffff;
}

.really-small-text {
  margin-top: 0;
  font-size: 0.70rem;
  line-height: 1.42;
  color: #d8d8d8;
}

.whole-story {
  float: right;
}

::-webkit-input-placeholder {
   font-size: 0.8rem;
}

a:hover:not(.feedback-button) {
  color: var(--purpley);
}

.left-align {
  text-align: left;
}

.numbers-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 150px;
  justify-content: space-around;
}

.numbers-duo {
  display: flex;
}

.icon-centered {
  display: flex;
  align-items: initial !important;
  margin-bottom: 54%;
}

.numbers-duo-text-large {
  font-family: Futurice;
  font-weight: normal;
  font-size: 2rem;
  line-height: 0.7;
  margin: 0;
  text-align: center;
}

.numbers-duo-text-small {
  font-family: Futurice-monospaced;
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
  margin: 0;
}

.numbers-duo-text {
  display: flex;
  align-items: flex-end;
}

.quotes-icon {
  display: flex;
}

.numbers-duo-icon {
  display: flex;
  align-items: end;
  
}

.calendar-icon {
  display: flex;
  margin: 0 auto;
}

.numbers-duo-calendar-container {
  height: 67%; 
}

.numbers-duo .numbers-duo-text {
  margin: 0 auto;
}

.numbers-duo-calendar-text {
  text-align: center;
  font-family: 'Futurice-monospaced';
}

.numbers-duo-calendar-text p {
  color: white;
  font-weight: normal;
  font-family: 'Futurice-monospaced', 'Roboto';
  position: relative;
}

.most-common-tooltip {
  height: 14px !important;
}

.icon-text {
  position: relative;
  flex: 1 1;
  font-family: 'Futurice-monospaced', 'Roboto';
  font-size: 0.85rem;
  margin: -45% auto;
}
.calendar-container {
  display: flex;
  flex-direction: column;
}

.common-title-container {
  margin: 0 auto;
}

.posts-tiny-icon {
  margin-left: 5px;
  vertical-align: middle;
}

.bar-hint {
  line-height: 100%;
}
@media screen and (max-width: 480px) {
  input::-webkit-input-placeholder {
    font-size: 0.7rem;
  }
  input::-moz-placeholder {
    font-size: 0.7rem;
  }
  input:-ms-input-placeholder {
    font-size: 0.7rem;
  }
  input::-ms-input-placeholder {
    font-size: 0.7rem;
  }
  input::placeholder {
    font-size: 0.7rem;
  }
}

.form {
  padding-left: 15px;
  display: flex;
  height: 40px;
  -webkit-appearance: none !important;
}

.form * {
  border-radius: 0;
}

.input {
  padding-left: 5px;
  flex: 3 1;
  -webkit-appearance: none !important;
  border: 0 none;
  margin: 0;
  line-height: 35px
}

.button {
  -webkit-appearance: none !important;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: #9f6ce3;
  flex: 1 1;
  align-items: center; 
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.feedback-button {
  border: 0;
  margin: 0;
  padding: 0;
  background-color: var(--purpley);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  min-height: 40px;
  font-size: 0.9rem;
  width: 150px;
  float: right;
}
div.tooltip {
    position: absolute;
    height: 28px;
    padding: 5px 10px;
    font-family: "Futurice";
    font-size: 12px;
    background: white;
	  border: solid 2px var(--beige);
    pointer-events: none;
}

.associated-chart {
    margin-bottom: 5%;
}

.no-results-found-container {
    margin-bottom: 5%;
}
.warning {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  padding-right: 10px;
}

.warning-container {
  display: relative;
  margin: 0 auto;
}

.warning-container p {
  text-align: center;
  margin-left: 500px;
}

.warning-text {
  margin-top: 0;
  line-height: 1.42;
}
.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.centered {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.top-margin {
  margin-top: 25px;
}

.bottom-margin {
  margin-bottom: 10px;
}

.margin-small-vertical {
  margin: 0;
  margin-bottom: 0.25em;
  margin-top: 0.25em;
}

.margin-small-vertical2 {
  margin: 0;
  margin-bottom: 0.5em;
  margin-top: 0.25em;
}

.monospaced {
  font-family: Futurice-monospaced;
}

.size-45 {
  font-size: 2.42rem;
}

.size-27 {
  font-size: 1.45rem;
}

.size-18 {
  font-size: 0.97rem;
}

.size-16 {
  font-size: 0.86rem;
}

.size-14 {
  font-size: 0.75rem;
}

.size-13 {
  font-size: 0.70rem;
}

.heading-1-white {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: black;
  padding-top: 0%;
}

.heading-2 {
  font-weight: 400;
  margin: 1rem;
  margin-top: 50px;
  font-size: 2rem;
  color: #ffffff;
}

.heading-3 {
  font-weight: bold;
  margin: 1rem;
  margin-top: 50px;
  margin-bottom: 10%;
  font-size: 2rem;
  color: #ffffff;
}

.heading-4 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #ffffff;
  margin: 2%;
  -webkit-margin-after: 0;
}

.search-heading {
  font-size: 1.2rem;
  font-family: 'SpaceMono', monospace;
  font-weight: bold;
}

.whiteish {
  color: #d8d8d8;
}

.white {
  color: #fff;
}

.yellow {
  color: #ffe638;
}

.darkyellow {
  color: #f4be97;
}

@media screen and (min-width: 480px) {
  .association-result {
    display: flex;
    flex-wrap: wrap;
  }
  .left-padding {
    padding-left: 75px;
  }
}

@media screen and (max-width: 480px) {
  .search-term-info {
    margin-top: 80px !important;
  }
  .left-padding {
    padding-left: 0;
  }
}

.search-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    max-width: 960px;
    margin: 0 auto;
    padding-top: 1%;
    padding-bottom: 1%;
    background: #212121;
}

.search-page {
  max-width: 960px;
  width: 95%;
  margin: 0 auto;
}

.search-term-info {
  padding-top: 3%;
  max-width: 960px;
  padding-left: 3%;
  padding-right: 3%;
  margin: 0 auto;
}

.form {
  width: 100%;
}

.association-result {
  max-width: 960px;
  min-height: 480px;
  padding-left: 3%;
  padding-right: 3%;
  border-bottom: 2px solid #717171;
  padding-bottom: 5%;
}

.association-result-left {
  flex: 2 1;
  font-size: 2rem;
  min-width: 320px;
}

.chart {
  flex: 4 1;
  min-width: 240px;
}

.minor-margin {
  margin: 2%;
}

.move-slider {
  margin-top: 4%;
  text-align: left;
}

.sk-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 128px;
  width: 128px;
}

.footer {
  margin: 2%;
}

.footer p {
  color: white;
  margin: 0;
  line-height: 1.79;
}

.footer a {
  color: white;
}

.chart-container {
  min-height: 300px;
}

.chart-side-bar .body-text {
  font-size: 0.85rem;
}
.chart-side-bar {
  margin-right: 10%;
}

.post-link-container {
  padding-top: 5px;
  width: 200px;
}

.post-link-icon {
  width: 30px;
  float: left;
}

.post-link-text {
  width: 170px;
  float: left;
}

.basket-link-container {
  padding-top: 15px;
}
@media only screen and (min-width: 1001px)  {
  .chart-side-bar {
    margin-top: 31%; 
  }
}

.slider-container {
  position: relative;
  height: 90px;
  color: #d8d8d8;
  margin-top: 13px;
}

.post-count-sliderx {
  position: absolute;
  margin-bottom: 20%;
}

.slider-text-top-left {
  position: absolute;
  top: 22%;
  left: 0;
}

.slider-text-top-right {
  position: absolute;
  top: 22%;
  right: 0;
}

svg {
    margin: -5% auto;
}
@media screen and (max-width:480px) {
  .search-box {
    flex-wrap: wrap;
    padding-right: 1%;
  }
  a {
    margin: 0 auto;
  }
}

/* On mobile devices we want a full-sided basket */
@media only screen and (max-width: 480px)  {
  .basket-modal {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-width: 481px)  {
  .basket-modal {
    width: 40%;
    float: left;
    margin: 3%;
  }
}

.basket-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  background-color: white;
  border: solid 4px var(--beige);
  font-family: 'Futurice', Roboto;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.basket-modal-heading {
  font-size: 0.9rem;
  top: 95px;
  width: 100%;
  background: white;
  border-bottom: 2px solid var(--beige);
  z-index: 1001;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.basket-modal-heading-text {
  text-align: center;
  padding-top: 1%;
  color: black;
  flex: 1 1;
}
.basket-modal-content {
  overflow: overlay;
  max-height: 800px;
  z-index: 1000;
  color: black;
  font-size: 1rem;
  margin: 2%;
}
.basket-modal-content p {
  margin: 0;
  color: black;
}
.highlighted {
  background-color: #f0d6c3;
}

.modal-section {
  border-bottom: 2px solid var(--beige);
}
.modal-section:last-of-type {
  border-bottom: 0;
}

.quote-modal-text {
  color: black;
  width: 80%;
  margin: 0 auto;
  padding-top: 4%;
  padding-bottom: 4%;
  line-height: 1.5;
  display: block;
  font-size: 1rem;
}

.quote-modal-thread-link {
  color: black;
  line-height: 1.5;
  font-size: 1rem;
}

.link-container {
  width: 80%;
  margin: 0 auto;
  text-align: end;
  padding-bottom: 3%;
}

.quote-modal-wrapper {
  width: 20%;
  height: 80%;
}

.quote-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Futurice, Roboto;
  height: 90%;
  width: 80%;
  margin: 0 auto;
  background-color: white;
  border: solid 3px var(--beige);
}
.quote-modal-heading {
  font-size: 0.9rem;
  top: 95px;
  width: 100%;
  background: white;
  border-bottom: 2px solid var(--beige);
  z-index: 1001;
}
.quote-modal-heading-text {
  text-align: center;
  padding-top: 1%;
  color: black;
  flex: 1 1;
}
.close-button {
  top: 0;
  right: 0;
  position: absolute;
  float: right;
  border: 0;
  background: none;
  cursor: pointer;
  font-size: 2rem;
}
.quote-modal-content {
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
}
.quote-modal-footer {
  bottom: 95px;
  width: 100%;
  background: #fff;
  border-top: 2px solid var(--beige);
  z-index: 1002;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,0.7) !important;
}



.quote-modal-page-links {
  color: black;
  margin: 0 auto;
  width: 80%;
}

.quote-modal-page-links span {
  margin: 2px;
  cursor: pointer;
}

.active {
  font-weight: bold;
}

.pagination {
  padding-top: 2.5%;
  padding-bottom: 2%;
  text-align: center;
  margin: 0 auto;
  -webkit-padding-start: 0;
}


.pagination p {
  color: black;
  padding: 0;
  margin: 0;
}

.pagination-prev-next {
  font-family: Futurice;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #4a4a4a;
  padding-left: 20px;
  padding-right: 20px;
}

.disabled a {
  pointer-events: none;
  cursor: default;
  color: gray !important;
}

.pagination-page-link {
  font-family: Futurice;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 1.7px;
  text-align: center;
  color: #4a4a4a;
}

.pagination a {
  color: black;
  padding: 5px;
  cursor: pointer;
}

.pagination-active a {
  color: var(--purpley);
  text-decoration: underline;
}

.pagination ul {
  display: inline-block;
}

.pagination li {
  display: inline-block;  
}

.pagination .break a {
  cursor: default;
}

.pagination-container {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.quote-modal-footer-hidden {
  visibility: hidden;
}
.associated {
    background-color: gray;
    height: 90%;
    margin-left: 2%;
    margin-bottom: 2%;
}

.no-results-found {
    margin-top: 20%;
    margin-bottom: 5%;
}
.not-found {
    max-width: 640px;
    width: 95%;
    margin: 0 auto;   
}
.faq-content h3 {
    margin-top: 100px;
}

.faq-content {
    max-width: 600px;
    width: 95%;
    margin: 0 auto;
  }

.faq-question {
  font-weight: bold;
  font-size: 0.97rem;
  color: white;
  line-height: 1.44;
  margin-bottom: 10px;
}

.faq-answer {
  font-weight: normal;
  font-size: 0.75rem;
  color: white;
  line-height: 1.44;
  margin-bottom: 50px;
}
@font-face {
  font-family: Futurice;
  src: local('Futurice'), url(/static/media/FuturiceRegular.37580a05.otf);
}

@font-face {
  font-family: Futurice;
  src: local('Futurice'), url(/static/media/FuturiceBold.9d228406.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Futurice-monospaced';
  src: local('Futurice-monospaced'), url(/static/media/FuturiceMonospaced.ffa7697d.otf);
}
/* Color palette */

:root {
	--sunshine-yellow-60: rgba(255, 230, 56, 0.6);
	--sunshine-yellow: #ffe638;
	--white-60: rgba(216, 216, 216, 0.6);
	--purpley: #9f6ce3;
	--white-two: #e6e6e6;
	--soft-green: #6ec872;
	--white: #d8d8d8;
	--hot-magenta: #d903ca;
	--coral: #ff5240;
	--beige: #f0d6c3;
	--bright-blue: #0068fd;
}

.main {
  padding-top: 2%;
  margin: 0 auto;
  font-family: 'Futurice', Roboto;
}

h1, h2, h3, h4, h5, h6 {
  color: white;
  font-weight: bold;
}

p {
  color: #d8d8d8;
  color: var(--white);
  font-family: Futurice, Roboto;
}

.is-tight {
  margin: 0;
}
.disclaimer-container {
  position: fixed;
  top: 20%;
  width: 75%;
  height: 60%;
  min-width: 360px;
  left: 50%;
  margin-left: -37%;
  background-color: var(--purpley);
  max-height: 450px;
}

.disclaimer-container h3 {
  text-align: center;
  color: white;
  font-family: 'Futurice';
  vertical-align: middle;
  font-weight: normal;
  font-size: 2rem;
  margin: 0;
  margin-top: 6%;
}

.disclaimer-container button {
  margin: 2%;
  height: 50px;
  width: 150px;
  background-color: var(--purpley);
  border: 2px solid white;
  font-size: 1rem;
  cursor: pointer;
  float: right;
  color: white;
  margin: 0;
  margin-right: 5%;
  margin-top: 5%;
}
.disclaimer-container button:hover {
  background-color: white;
  color: var(--purpley);
}  
.disclaimer-container p {
  color: white;
  font-size: 0.9rem;
  margin: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
  font-weight: normal;
}
