@media screen and (max-width: 480px) {
  input::placeholder {
    font-size: 0.7rem;
  }
}

.form {
  padding-left: 15px;
  display: flex;
  height: 40px;
  -webkit-appearance: none !important;
}

.form * {
  border-radius: 0;
}

.input {
  padding-left: 5px;
  flex: 3;
  -webkit-appearance: none !important;
  border: 0 none;
  margin: 0;
  line-height: 35px
}

.button {
  -webkit-appearance: none !important;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: #9f6ce3;
  flex: 1;
  align-items: center; 
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}